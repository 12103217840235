
const Constant = {
    token: 'idToken',
    refreshToken: 'adminRefreshToken',
    success: 'Success',

    // Success

    SuccessCode: 200,
 
    // Error
    Success: 'Success',
    error: 'Error',
    delete: 'Delete',
    defaultErrorMessage: 'Something went wrong, please try again!',

    Warning: 'Warning',

    REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
    REACT_APP_REGION: process.env.REACT_APP_REGION,
    REACT_APP_USERPOOLID: process.env.REACT_APP_USERPOOLID,
    REACT_APP_USERCLIENTID: process.env.REACT_APP_USERCLIENTID,
    REACT_APP_DOMAIN: process.env.REACT_APP_DOMAIN,
    REACT_APP_IDENTITYPOOLID: process.env.REACT_APP_IDENTITYPOOLID,
    REACT_APP_IDENTITYPOOLID_Admin: process.env.REACT_APP_IDENTITYPOOLID_Admin,
    REACT_APP_S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
    REACT_APP_SignIn_URL: process.env.REACT_APP_SignIn_URL,
    REACT_APP_SignOut_URL: process.env.REACT_APP_SignOut_URL

};

export default Constant;
