import React, { Suspense } from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import { Skeleton } from "antd";
import Routes from "../Global/Routes";
import Login from "../Pages/Login";
import HomePage from "../Pages/Home";
import LeaderboardPage from "../Pages/Leaderboard";
import AdminPage from "../Pages/Admin";
import SentenceList from "../Pages/SentenceList";
import AccessRoute from "./privateRoute";
import PublicRoute from "./publicRoute";

const Router = () => (
    <Suspense fallback={<Skeleton />}>
        <Switch>
            <Route
                path="/"
                element={<Navigate to={Routes.login} replace />}
            />
            <Route
                caseSensitive
                path={Routes.login}
                element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                }
            />

            <Route
                caseSensitive
                path={Routes.home}
                element={
                    <AccessRoute>
                        <HomePage />
                    </AccessRoute>
                }
            />

            <Route
                caseSensitive
                path={Routes.leaderboard}
                element={
                    <AccessRoute>
                        <LeaderboardPage />
                    </AccessRoute>
                }
            />

            <Route
                caseSensitive
                path={Routes.admin}
                element={
                    <AccessRoute adminOnly>
                        <AdminPage />
                    </AccessRoute>
                }
            />

            <Route
                caseSensitive
                path={Routes.sentencelist}
                element={
                    <AccessRoute adminOnly>
                        <SentenceList />
                    </AccessRoute>
                }
            />
        </Switch>
    </Suspense>
);

export default Router;
