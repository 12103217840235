import api from './api'; 

class LeaderBoardService {

  getLeaderBoard = async (date) => {
    try {
      const url = `/leaderboard?date=${date}`;
      const response = await api.get(url);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new LeaderBoardService();
