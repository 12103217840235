import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Space, Popconfirm,Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import SentenceServices from '../../Services/SentenceServices';
import './Sentencelist.css';
import SlideMenu from '../SlideMenu';
import Utility from "../../Global/Utility";

const { Text } = Typography;

const SentenceList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [userEmail, setUserEmail] = useState("");

    // Fetch sentences with pagination
    const fetchData = async (pagination) => {
        setLoading(true);
        try {
            const response = await SentenceServices.getSentenceList(pagination);
            if (response && response.data) {
                setData(response.data.sentences);
                setPagination({
                    ...pagination,
                    total: response.data.total,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching sentences:", error);
        }
    };

    const onPaginationChange = (page, pageSize) => {
        setPagination({ current: page, pageSize });
        fetchData({ current: page, pageSize });
    };

    const handleEdit = (value, sentenceId) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.sentence_id === sentenceId);
        if (index > -1) {
            newData[index].sentence = value;
            setData(newData);
        }
    };

    const handleDelete = async (sentenceId) => {
        try {
            await SentenceServices.deleteSentence({ sentenceId });
            console.log("Sentence deleted successfully");
            fetchData(pagination);
        } catch (error) {
            console.error("Error deleting sentence:", error);
        }
    };


    const handleConfirm = async (sentenceId, sentence) => {
        try {
            const data = { sentenceId, sentence };
            const response = await SentenceServices.confirmSentence(data);
            console.log("Confirmed sentence:", response);
            fetchData(pagination);
        } catch (error) {
            console.error("Error confirming sentence:", error);
        }
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (_, __, index) => {
                const pageOffset = (pagination.current - 1) * pagination.pageSize;
                return <span className="table-cell-text">{index + 1 + pageOffset}</span>;
            },
        },
        {
            title: 'Sentence',
            dataIndex: 'sentence',
            key: 'sentence',
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => handleEdit(e.target.value, record.sentence_id)}  // Pass unique identifier
                    className="editable-input"
                />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space className="action-buttons">
                    <Button
                        icon={<CheckCircleOutlined />}
                        type="primary"
                        className="action-button approve-button"
                        onClick={() => handleConfirm(record.sentence_id, record.sentence)}
                    >
                        Confirm
                    </Button>
                    <Popconfirm
                        title="Are you sure to delete this sentence?"
                        onConfirm={() => handleDelete(record.sentence_id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<CloseCircleOutlined />} danger className="reject-button">
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    // Fetch sentences when component mounts
    useEffect(() => {
        fetchData(pagination);
        const getEmail = async () => {
            try {
              const email = await Utility.fetchEmail();
              setUserEmail(email);
            } catch (error) {
              console.error("Error fetching email:", error);
            }
          };
          getEmail();
    }, []);

    return (
        <div className="sentence-list-container">
            <div className="top-bar">
                <SlideMenu />
                <div className="title">
                    <h2 className="page-title">Sentence List</h2>
                    <Text className="user-email">{userEmail}</Text>
                </div>
            </div>

            <div className="content-section">
                <Space direction="vertical" size="large" className="content-space w-100">
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowClassName="editable-row"
                        pagination={{
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                            total: pagination.total,
                            onChange: onPaginationChange,
                        }}
                        className="sentence-table"
                        loading={loading}
                    />
                </Space>
            </div>
        </div>
    );
};

export default SentenceList;
