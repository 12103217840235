import AWS from "aws-sdk";
import Constant from "../Global/Constant";
import Utility from "./Utility";

const fetchAWSCredentials = async (idToken) => {
    if (!idToken) throw new Error("User is not authenticated. idToken is missing.");

    const decoded = Utility.parseJwt(idToken);
    const userGroups = decoded['cognito:groups'] || [];

    let identityPoolId;
    if (userGroups.includes('Admin')) {
        identityPoolId = Constant.REACT_APP_IDENTITYPOOLID_Admin;
    } else {
        identityPoolId = Constant.REACT_APP_IDENTITYPOOLID;
    }

    const loginsKey = `cognito-idp.${Constant.REACT_APP_REGION}.amazonaws.com/${Constant.REACT_APP_USERPOOLID}`;
    AWS.config.region = Constant.REACT_APP_REGION;

    if (!AWS.config.credentials || AWS.config.credentials.expired) {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
            Logins: {
                [loginsKey]: idToken,
            },
        });

        await AWS.config.credentials.getPromise();
    }

    return AWS.config.credentials;
};

export default fetchAWSCredentials;
