import React, { useEffect, useState } from "react";
import { Table, Typography, Space, DatePicker } from "antd";
import LeaderboardServices from "../../Services/LeaderboardServices";
import SlideMenu from "../SlideMenu";
import "./Leaderboard.css";
import Utility from "../../Global/Utility";
import dayjs from "dayjs";

const { Title, Text } = Typography;

const LeaderboardPage = () => {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [selectedDate, setSelectedDate] = useState(dayjs());

    const handleDateChange = (date, dateString) => {
        if (date) {
            setSelectedDate(date);
            fetchLeaderboardData(dateString);
        }
    };

    const columns = [
        {
            title: "#",
            dataIndex: "rank",
            key: "rank",
            render: (text) => <span className="table-cell-text">{text}</span>,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <Space size="middle">
                    <img src={record.picture} alt="Profile" className="profile-img" />
                    <span className="table-cell-text">{text}</span>
                </Space>
            ),
        },
        {
            title: "Score",
            dataIndex: "actualscore",
            key: "actualscore",
            render: (text) => <span className="table-cell-text">{text}</span>,
        },
        {
            title: (
                <div style={{ textAlign: 'center' }} className="date-picker-column">
                    <DatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        format="YYYY-MM-DD"
                        style={{ borderRadius: '8px', padding: '0 10px' }}
                        allowClear={false}
                    />
                </div>
            ),
            key: "datePicker",
            className: "date-picker-column",
            render: () => null,
        }        
    ];

    const fetchLeaderboardData = async (date) => {
        try {
            setLoading(true);
            const response = await LeaderboardServices.getLeaderBoard(date);

            const mappedData = response.data.map((item) => ({
                key: item.userid,
                rank: item.rank,
                name: item.name,
                picture: item.picture,
                actualscore: item.actualscore,
            }));

            setLeaderboardData(mappedData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Failed to fetch leaderboard data");
        }
    };

    useEffect(() => {
        if (selectedDate) {
            fetchLeaderboardData(selectedDate.format("YYYY-MM-DD"));
        }
        const getEmail = async () => {
            try {
                const email = await Utility.fetchEmail();
                setUserEmail(email);
            } catch (error) {
                console.error("Error fetching email:", error);
            }
        };
        getEmail();

    }, [selectedDate]);

    return (
        <div className="leaderboard-container">
            {/* Slide Menu */}
            <div className="top-bar">
                <SlideMenu />
                <div className="title">
                    <Title level={4} className="page-title">Leaderboard</Title>
                    <Text className="user-email">{userEmail}</Text>
                </div>
            </div>

            {/* Leaderboard Table */}
            <div className="content-section">
                <Space direction="vertical" size="large" className="content-space w-100">
                    <div style={{ textAlign: 'right' }} className="above-table-datepicker">
                        <DatePicker
                            value={selectedDate}
                            onChange={handleDateChange}
                            format="YYYY-MM-DD"
                            style={{ borderRadius: '8px', padding: '0 10px' }}
                            allowClear={false}
                        />
                    </div>
                    <Table
                        columns={columns}
                        dataSource={leaderboardData}
                        pagination={false}
                        rowClassName="table-row"
                        className="leaderboard-table"
                        loading={loading}
                    />
                </Space>
            </div>
        </div>
    );
};

export default LeaderboardPage;
