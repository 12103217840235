import React, { useState, useRef, useEffect } from "react";
import { Button, Typography, Space, Layout } from "antd";
import { AudioFilled, CloseOutlined } from "@ant-design/icons";
import { getCurrentUser } from "aws-amplify/auth";
import FileService from "../../Services/HomeServices";
import AWS from "aws-sdk";
import AudioMotionAnalyzer from "audiomotion-analyzer";
import "./Home.css";
import Constant from "../../Global/Constant";
import SlideMenu from "../SlideMenu";
import Utility from "../../Global/Utility";
import fetchAWSCredentials from '../../Global/IdentityCred';

const { Title, Text } = Typography;

const HomePage = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [sentenceData, setSentenceData] = useState({ sentence: "", sentenceId: null });
  const [loadingSentence, setLoadingSentence] = useState(true);
  const [userEmail, setUserEmail] = useState("");

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyzerRef = useRef(null);
  const visualizerContainerRef = useRef(null);
  const uploadRequestRef = useRef(null);

  const checkPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
  
      if (permissionStatus.state === 'denied') {
        alert('Microphone access is denied. Please enable it in your browser settings.');
      } else {
        startRecording();
      }
    } catch (error) {
      console.error("Error checking microphone permission:", error);
      startRecording();
    }
  };
  
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  
      if (stream) {
        streamRef.current = stream;
  
        const audioContext = new AudioContext();
        audioContextRef.current = audioContext;
  
        const source = audioContext.createMediaStreamSource(stream);
  
        if (visualizerContainerRef.current) {
          analyzerRef.current = new AudioMotionAnalyzer(visualizerContainerRef.current, {
            source: source,
            connectSpeakers: false,
            showBgColor: true,
            height: 100,
          });
        }
  
        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunksRef.current = [];
  
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
  
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp3" });
          setIsUploading(true);
          uploadFileToS3(audioBlob);
        };
  
        mediaRecorderRef.current.start();
        setIsRecording(true);
      }
    } catch (error) {
      if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
        alert("Microphone access was denied. Please turn on the microphone in your browser settings.");
      } else if (error.name === "NotFoundError") {
        alert("No microphone found. Please check your microphone settings.");
      } else {
        console.error("Error accessing microphone:", error);
        alert("An error occurred while accessing the microphone. Please try again.");
      }
    }
  };
  
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      const tracks = streamRef.current?.getTracks();
      tracks?.forEach((track) => track.stop());
      setIsRecording(false);

      if (analyzerRef.current) {
        analyzerRef.current.destroy();
        analyzerRef.current = null;
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
    }
  };

  const cancelUpload = () => {
    if (uploadRequestRef.current) {
      uploadRequestRef.current.abort(); // Cancel the S3 upload
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const uploadFileToS3 = async (audioBlob) => {
    try {
        const currentUserResponse = await getCurrentUser();
        const user = currentUserResponse.username;
        const userId = currentUserResponse.userId;

        if (!user) throw new Error("User information is missing in the response");

        const idToken = localStorage.getItem("idToken");
        if (!idToken) throw new Error("User is not authenticated. idToken is missing.");

        await fetchAWSCredentials(idToken);

        const fileName = `${userId}_${Date.now()}.mp3`;
        const S3_BUCKET = Constant.REACT_APP_S3_BUCKET;
        const REGION = Constant.REACT_APP_REGION;

        const s3 = new AWS.S3({
            region: REGION,
        });

        const params = {
            Bucket: S3_BUCKET,
            Key: `${user}/${fileName}`,
            Body: audioBlob,
            ContentType: "audio/mp3",
        };

        const upload = s3.upload(params).on("httpUploadProgress", (evt) => {
            setUploadProgress(parseInt((evt.loaded * 100) / evt.total));
        });

        uploadRequestRef.current = upload;
        await upload.promise();

        setIsUploading(false);
        setUploadProgress(0);

        const sentence_id = sentenceData.sentenceId;
        if (!sentence_id) throw new Error("Sentence ID is missing.");
        const data = { sentence_id, fileKey: `${user}/${fileName}` };
        await FileService.uploadFile(data);

        await FetchSentence();
    } catch (error) {
        if (error.message !== "Request aborted") {
            console.error("Error uploading to S3:", error.message || error);
        }
    }
};

  const FetchSentence = async () => {
    try {
      setLoadingSentence(true);
      const response = await FileService.getSentence();
      const { sentence_id, sentence } = response.data;
      setSentenceData({ sentence, sentenceId: sentence_id });
    } catch (error) {
      console.error("Error during initialization:", error);
      alert(`An error occurred while loading the sentence: ${error.message || error}.`);
    } finally {
      setLoadingSentence(false);
    }
  };

  useEffect(() => {
    FetchSentence();
    const getEmail = async () => {
      try {
        const email = await Utility.fetchEmail();
        setUserEmail(email);
      } catch (error) {
        console.error("Error fetching email:", error);
      }
    };
    getEmail();
  }, []);

  return (
    <Layout className="home-layout">
      {/* Top Bar */}
      <div className="top-bar">
        <SlideMenu />
        <div className="title">
        <Title level={4} className="app-title">
          OJO
        </Title>
        <Text className="user-email">{userEmail}</Text>
        </div>
      </div>

      {/* Sentence Display */}
      <div className="content-section">
        <Space direction="vertical" size="large" className="content-space">
          <Title level={5} className="sentence-title">
            Sentence to Record
          </Title>
          <Text className="sentence-text">
            {loadingSentence ? "Loading sentence..." : sentenceData.sentence}
          </Text>
          {!loadingSentence && (
            <>
              <div
                className="visualizer-container visible"
                ref={visualizerContainerRef}
              ></div>
              {/* Recording/Uploading Controls */}
              <Space size="middle">
                {!isRecording && !isUploading && (
                  <Button
                    type="primary"
                    icon={<AudioFilled />}
                    onClick={checkPermission}
                  >
                    Record
                  </Button>
                )}
                {isRecording && (
                  <Button
                    type="primary"
                    danger
                    icon={<AudioFilled />}
                    onClick={stopRecording}
                  >
                    Stop
                  </Button>
                )}
                {isUploading && (
                  <>
                    <Text type="secondary">
                      Uploading... {uploadProgress}%
                    </Text>
                    <Button
                      type="default"
                      danger
                      icon={<CloseOutlined />}
                      onClick={cancelUpload}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Space>
            </>
          )}
        </Space>
      </div>
    </Layout>
  );
};

export default HomePage;
