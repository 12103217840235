import React, { useState, useEffect } from 'react';
import { Table, Button, Typography, Space } from 'antd';
import { PlayCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import AdminServices from '../../Services/AdminServices';
import AWS from 'aws-sdk';
import './Admin.css';
import Constant from '../../Global/Constant';
import SlideMenu from '../SlideMenu';
import Utility from "../../Global/Utility";
import fetchAWSCredentials from '../../Global/IdentityCred';

const { Title, Text } = Typography;

const AdminPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingStates, setLoadingStates] = useState({});
    const [currentAudio, setCurrentAudio] = useState(null);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
    const [userEmail, setUserEmail] = useState("");
    const [awsCredentialsFetched, setAwsCredentialsFetched] = useState(false);

    const fetchAWSConfig = async () => {
        if (awsCredentialsFetched) return;
    
        try {
            const idToken = localStorage.getItem("idToken");
            if (!idToken) throw new Error("User is not authenticated. idToken is missing.");
            await fetchAWSCredentials(idToken);
    
            setAwsCredentialsFetched(true);
        } catch (error) {
            console.error("Error fetching AWS credentials:", error);
        }
    };    

    const fetchData = async (pagination) => {
        setLoading(true);
        try {
            await fetchAWSConfig();
            
            const response = await AdminServices.getFiles(pagination);
            if (response && response.data) {
                const updatedData = await Promise.all(
                    response.data.recordings.map(async (item) => {
                        const audioFileUrl = await fetchAudioUrl(item.audio);
                        return { ...item, audio: audioFileUrl };
                    })
                );
                setData(updatedData);
                setPagination({
                    ...pagination,
                    total: response.data.total,
                });
            }
        } catch (error) {
            console.error("Error fetching file data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(pagination);
        const getEmail = async () => {
            try {
                const email = await Utility.fetchEmail();
                setUserEmail(email);
            } catch (error) {
                console.error("Error fetching email:", error);
            }
        };
        getEmail();
    }, []);

    const fetchAudioUrl = async (audioKey) => {
        const S3_BUCKET = Constant.REACT_APP_S3_BUCKET;
        const REGION = Constant.REACT_APP_REGION;

        try {
            const s3 = new AWS.S3({
                region: REGION,
            });

            const params = {
                Bucket: S3_BUCKET,
                Key: audioKey,
            };

            return new Promise((resolve, reject) => {
                s3.getSignedUrl('getObject', params, (error, url) => {
                    if (error) {
                        console.error("Error fetching audio file:", error);
                        reject('');
                    } else {
                        resolve(url);
                    }
                });
            });
        } catch (error) {
            console.error("Error in fetchAudioUrl:", error.message || error);
            return '';
        }
    };

    const handleAction = async (key, action) => {
        const record = data.find(item => item.recordingId === key);

        setLoadingStates(prevStates => ({
            ...prevStates,
            [key]: { ...prevStates[key], [action]: true }
        }));

        try {
            if (action === 'Approved') {
                const recordingId = record.recordingId;
                const queryParams = { recording_id: recordingId };

                await AdminServices.approveFile(queryParams);
                setCurrentAudio(null);
                fetchData(pagination);

                setData((prevData) =>
                    prevData.map((item) =>
                        item.key === key ? { ...item, status: 'Approved' } : item
                    )
                );
            } else if (action === 'Rejected') {
                const fullAudioUrl = record.audio;
                const urlParts = new URL(fullAudioUrl);
                const rawKey = decodeURIComponent(urlParts.pathname.substring(1));

                const S3_BUCKET = Constant.REACT_APP_S3_BUCKET;
                const REGION = Constant.REACT_APP_REGION;

                AWS.config.update({
                    accessKeyId: Constant.REACT_APP_ACCESSKEYID,
                    secretAccessKey: Constant.REACT_APP_SECRETACCESSKEY,
                });

                const s3 = new AWS.S3({
                    region: REGION,
                });

                const params = {
                    Bucket: S3_BUCKET,
                    Key: rawKey,
                };

                await Promise.all([
                    AdminServices.rejectFile({ recording_id: record.recordingId }, record.audio),
                    s3.deleteObject(params).promise()
                ]);
                setCurrentAudio(null);
                fetchData(pagination);

                setData((prevData) =>
                    prevData.map((item) =>
                        item.key === key ? { ...item, status: 'Rejected' } : item
                    )
                );
            }
        } catch (error) {
            console.error(`Error ${action.toLowerCase()}ing file:`, error);
        } finally {
            setLoadingStates(prevStates => ({
                ...prevStates,
                [key]: { ...prevStates[key], [action]: false }
            }));
        }
    };

    const handleAudioPlay = (audioUrl) => {
        if (currentAudio && currentAudio.src === audioUrl && !currentAudio.paused) {
            currentAudio.pause();
            setCurrentAudio(null);
        } else {
            if (currentAudio && !currentAudio.paused) {
                currentAudio.pause();
                setCurrentAudio(null);
            }

            const audio = new Audio(audioUrl);
            audio.play();
            setCurrentAudio(audio);

            audio.onended = () => {
                setCurrentAudio(null);
            };
        }
    };

    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (_, __, index) => {
                const pageOffset = (pagination.current - 1) * pagination.pageSize;
                return <span className="table-cell-text">{index + 1 + pageOffset}</span>;
            },
        },
        {
            title: 'Sentence',
            dataIndex: 'sentence',
            key: 'sentence',
            render: (text) => <span className="table-cell-text">{text}</span>,
        },
        {
            title: 'User',
            dataIndex: 'userName',
            key: 'userName',
            render: (text) => <span className="table-cell-text">{text}</span>,
        },
        {
            title: 'Play',
            key: 'play',
            render: (_, record) => (
                <Button
                    icon={currentAudio && currentAudio.src === record.audio && !currentAudio.paused ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                    className="action-button"
                    onClick={() => handleAudioPlay(record.audio)}
                >
                    {currentAudio && currentAudio.src === record.audio && !currentAudio.paused ? 'Pause' : 'Play'}
                </Button>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className="action-buttons">
                    <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        className="action-button approve-button"
                        onClick={() => handleAction(record.recordingId, 'Approved')}
                        loading={loadingStates[record.recordingId]?.Approved || false}
                    >
                        Approve
                    </Button>
                    <Button
                        type="danger"
                        icon={<CloseCircleOutlined />}
                        className="action-button reject-button"
                        onClick={() => handleAction(record.recordingId, 'Rejected')}
                        loading={loadingStates[record.recordingId]?.Rejected || false}
                    >
                        Reject
                    </Button>
                </div>
            ),
        },
    ];

    const onPaginationChange = (page, pageSize) => {
        setPagination({ current: page, pageSize });
        fetchData({ current: page, pageSize });
    };

    return (
        <div className="admin-container">
            <div className="top-bar">
                <SlideMenu />
                <div className="title">
                    <Title level={4} className="page-title">Admin Dashboard</Title>
                    <Text className="user-email">{userEmail}</Text>
                </div>
            </div>

            <div className="content-section">
                <Space direction="vertical" size="large" className="content-space w-100">
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowClassName={(record) =>
                            record.status === 'Approved'
                                ? 'approved-row'
                                : record.status === 'Rejected'
                                    ? 'rejected-row'
                                    : ''
                        }
                        pagination={{
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                            total: pagination.total,
                            onChange: onPaginationChange,
                        }}
                        className="admin-table"
                        loading={loading}
                    />
                </Space>
            </div>
        </div>
    );
};

export default AdminPage;
