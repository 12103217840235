import api from './api'; 

class HomeService {
  uploadFile = async (data) => {
    try {
      const response = await api.post('/uploadfile', data);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getSentence = async () => {
    try {
      const url = `/getsentence`;
      const response = await api.get(url);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new HomeService();

