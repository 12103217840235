import { Amplify } from "aws-amplify";
import {
  getCurrentUser,
  fetchAuthSession,
  signInWithRedirect,
  signOut,
  fetchUserAttributes
} from 'aws-amplify/auth';
import awsconfig from '../aws-exports';
import Routes from "../Global/Routes";
import Utility from "../Global/Utility";
import Constant from "../Global/Constant";

Amplify.configure(awsconfig);

class AuthStore {
  constructor() {
    this.email = "";
    this.password = "";
    this.error = "";
    this.currentUser = null;
  }

  async getCurrentUser(callback) {
    try {
      const token = localStorage.getItem(Constant.token);

      if (!token) {
        throw new Error('Token not found');
      }

      const isValidSession = await this.isSessionValid(token);
      if (!isValidSession) {
        return;
      }

      const user = await getCurrentUser();
      this.currentUser = {
        user: user.username,
        userId: user.userId
      };
      callback(undefined, this.currentUser);
    } catch (error) {
      callback(error.message);
    }
  }

  async refreshSession() {
    try {
      const session = await fetchAuthSession();
      const jwtToken = session?.tokens?.idToken?.toString();
      if (jwtToken) {
        localStorage.setItem(Constant.token, jwtToken);
        return true;
      } else {
        throw new Error('JWT token is undefined');
      }
    } catch (error) {
      console.error('Error refreshing session:', error);
      await this.signOut();
      return false;
    }
  }

  async isSessionValid(token) {
    const decoded = Utility.parseJwt(token);
    const currentTime = Math.ceil(new Date().getTime() / 1000);
    const currentExpiry = Math.ceil(new Date(decoded.exp).getTime());
    const fiveMinutes = 300;
    const logUserOut = currentExpiry - fiveMinutes < currentTime;
    if (logUserOut) {
      const refreshSuccessful = await this.refreshSession();
      return refreshSuccessful;
    }
    return true;
  }

  async signInGoogle(callback) {
    try {
      await signInWithRedirect({
        provider: 'Google'
      }); 
      callback();
    } catch (error) {
      console.log("err", error);
      if (error.name === "UserAlreadyAuthenticatedException") {
        await this.signOut();
        await this.signInGoogle(callback);
      } else {
        callback(error.message);
      }
    }
  }
   

  async signOut() {
    try {
      await signOut();
      this.currentUser = null;
      localStorage.removeItem(Constant.token);
      window.location.href = Routes.login;
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  async fetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      return userAttributes;

    } catch (error) {
      console.error('Error fetching user attributes:', error);
      return error.message;
    }
  }  
 
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthStore();
