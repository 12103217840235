import Constant from "./Global/Constant";

const awsconfig = {
    Auth: {
        Cognito: {
            region: Constant.REACT_APP_REGION,
            userPoolId: Constant.REACT_APP_USERPOOLID,
            userPoolClientId: Constant.REACT_APP_USERCLIENTID,
            loginWith: {
                oauth: {
                    domain: Constant.REACT_APP_DOMAIN,
                    scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: [Constant.REACT_APP_SignIn_URL],
                    redirectSignOut: [Constant.REACT_APP_SignOut_URL],
                    responseType: 'code',
                },
            },
        },
    },
};

export default awsconfig;
 