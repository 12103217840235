import React, { useState, useEffect } from "react";
import { Drawer, Button } from "antd";
import { HomeOutlined, TrophyOutlined, LogoutOutlined, MenuOutlined, EditOutlined,UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Utility from "../../Global/Utility";
import "./SlideMenu.css";
import authStore from "../../Store/authStore";

const SlideMenu = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("idToken");
        if (token) {
            const decoded = Utility.parseJwt(token);
            const userGroups = decoded["cognito:groups"] || [];
            setIsAdmin(userGroups.includes("Admin"));
        }
    }, []);

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleNavigation = (route) => {
        setIsDrawerOpen(false); // Close the drawer after navigation
        navigate(route);
    };

    const handleSignOut = async () => {
        const confirmSignOut = window.confirm("Are you sure you want to log out?");
        if (confirmSignOut) {
            await authStore.signOut();
            navigate("/login");
        }
    };

    return (
        <>
            <Button
                type="text"
                icon={<MenuOutlined />}
                className="menu-button"
                onClick={handleDrawerToggle}
            />
            <Drawer
                title="Menu"
                placement="left"
                onClose={handleDrawerToggle}
                open={isDrawerOpen}
                className="drawer-menu"
            >

                <Button
                    type="text"
                    icon={<HomeOutlined />}
                    className="drawer-button"
                    onClick={() => handleNavigation("/home")}
                >
                    Home
                </Button>
                <Button
                    type="text"
                    icon={<TrophyOutlined />}
                    className="drawer-button"
                    onClick={() => handleNavigation("/leaderboard")}
                >
                    Leaderboard
                </Button>
                {isAdmin && (
                    <><Button
                        type="text"
                        icon={<UserOutlined />}
                        className="drawer-button"
                        onClick={() => handleNavigation("/admin")}
                    >
                        Admin
                    </Button><Button
                        type="text"
                        icon={<EditOutlined />}
                        className="drawer-button"
                        onClick={() => handleNavigation("/sentencelist")}
                    >
                            Sentencelist
                        </Button></>
                )}
                <Button
                    type="text"
                    icon={<LogoutOutlined />}
                    className="drawer-button"
                    onClick={handleSignOut}
                >
                    Logout
                </Button>
            </Drawer>
        </>
    );
};

export default SlideMenu;
