import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; 
import { Navigate } from 'react-router-dom';
import { Spin } from 'antd'; 
import Routes from '../Global/Routes';
import authStore from '../Store/authStore';
import Utility from '../Global/Utility';

const AccessRoute = ({ children, adminOnly }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        await authStore.refreshSession();
        await authStore.getCurrentUser(async (error, currentUser) => {
          if (error) {
            setIsAuthenticated(false);
          } else {
            setIsAuthenticated(currentUser !== null);
          }

          const token = localStorage.getItem("idToken");
          if (token) {
            const decoded = Utility.parseJwt(token);
            const userGroups = decoded['cognito:groups'] || [];
            if (adminOnly) {
              setIsAdmin(userGroups.includes('Admin'));
            } else {
              setIsAdmin(true);
            }
          } else {
            setIsAuthenticated(false);
          }
          setIsLoading(false);
        });
      } catch (error) {
        console.error('Error in useEffect:', error); 
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };

    fetchCurrentUser();
  }, [adminOnly]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!isAuthenticated || (adminOnly && !isAdmin)) {
    return <Navigate to={Routes.login} replace />;
  }

  return children;
};

AccessRoute.propTypes = {
  children: PropTypes.node.isRequired,
  adminOnly: PropTypes.bool,
};

AccessRoute.defaultProps = {
  adminOnly: false,
};

export default AccessRoute;
