import React, { useState } from "react";
import { Button, Row, Col, Typography, Space, Card } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import authStore from "../../Store/authStore";
import "./LoginPage.css";

const { Title, Text } = Typography;

const LoginPage = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleGoogleLogin = () => {
        setLoading(true);
        authStore.signInGoogle(async (error) => {
            if (error) {
                setErrorMessage(error);
                setLoading(false);
            }
        });
    };

    return (
        <div className="login-container">
            <Row justify="center" align="middle" className="login-row">
                <Col xs={22} sm={16} md={12} lg={8}>
                    <Card className="login-card" bordered={false}>
                        <Space direction="vertical" style={{ width: "100%" }} size="large">
                            {/* Logo and Welcome Text */}
                            <div className="login-header">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 200 200"
                                    className="logo-svg"
                                    width="100"
                                    height="100"
                                >
                                    <circle cx="100" cy="100" r="90" fill="url(#gradient)" />
                                    <text
                                        x="50%"
                                        y="50%"
                                        textAnchor="middle"
                                        dy=".3em"
                                        fontSize="40"
                                        fontWeight="bold"
                                        fill="#DAD7CD"
                                    >
                                        OJO
                                    </text>
                                    <defs>
                                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                            <stop offset="0%" stopColor="#A3B18A" />
                                            <stop offset="100%" stopColor="#344E41" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                                <h1 className="welcome-text">Welcome to OJO</h1>
                                <p className="login-description">
                                    Help us improve speech-to-text technology for Malayalam! Record your voice for the displayed text, and upload it. Let's make voice recognition smarter together.
                                </p>
                            </div>

                            {/* Error Message */}
                            {errorMessage && (
                                <div className="error-message">
                                    <Text type="danger">{errorMessage}</Text>
                                </div>
                            )}

                            {/* Google Login Button */}
                            <Button
                                type="primary"
                                icon={<GoogleOutlined />}
                                size="large"
                                block
                                onClick={handleGoogleLogin}
                                className="google-login-button"
                                loading={loading}
                            >
                                Sign in with Google
                            </Button>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default LoginPage;
