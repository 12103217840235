import api from './api';
import queryString from 'query-string';

class SentenceListService {
    getSentenceList = async (pagination) => {
        try {
            const { current, pageSize } = pagination;
            const offset = (current - 1) * pageSize;
            const limit = pageSize;

            const query = queryString.stringify({ limit, offset }, { skipNull: true });
            const url = `/getsentencelist?${query}`;
            const response = await api.get(url);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    confirmSentence = async (data) => {
        try {
          const response = await api.post('/confirmSentence', data);
          return Promise.resolve(response.data);
        } catch (error) {
          return Promise.reject(error);
        }
      };

    deleteSentence = async (queryParams) => {
        try {
            const query = queryString.stringify(queryParams, { skipNull: true });
            const url = `/deleteSentence?${query}`;
            const response = await api.get(url);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export default new SentenceListService();
