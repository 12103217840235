import api from './api';
import queryString from 'query-string';


class AdminService {

   getFiles = async (pagination) => {
    try {
        const { current, pageSize } = pagination;
        const offset = (current - 1) * pageSize;
        const limit = pageSize;

        const query = queryString.stringify({ limit, offset }, { skipNull: true });
        const url = `/getfile?${query}`;
        const response = await api.get(url);
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

    approveFile = async (queryParams) => {
        try {
            const query = queryString.stringify(queryParams, { skipNull: true });
            const url = `/approvefile?${query}`;
            const response = await api.get(url);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

     rejectFile = async (queryParams, audio) => {
        try {
            const query = queryString.stringify(queryParams, { skipNull: true });
            const url = `/rejectfile?${query}`;
            const response = await api.get(url); 
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };
    
}

export default new AdminService();

