import axios from "axios";
import authStore from "../Store/authStore";
import Constant from "../Global/Constant";

class API {
  constructor() {
    axios.defaults.baseURL = Constant.REACT_APP_BASE_URL;

    this.creds = {
      tokens: undefined,
      credentials: undefined,
      identityId: undefined,
      userSub: undefined,
    };

    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(Constant.token);
        if (token) {
          authStore.isSessionValid(token);
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          try {
            await authStore.refreshSession();
            const newToken = localStorage.getItem(Constant.token);
            if (newToken) {
              originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            }
            return axios(originalRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }

        if (error.response === undefined) {
          return;
        }
        return Promise.reject(error);
      }
    );
  }

  async get(url, config) {
    return axios.get(url, config);
  }

  async post(url, data) {
    return axios.post(url, data);
  }

  async put(url, data) {
    return axios.put(url, data);
  }

  async delete(url) {
    return axios.delete(url);
  }
}

export default new API();
